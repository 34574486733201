import FetchInterceptor from "configs/FetchInterceptor";

class gpointExchangeServices {
  static async getAll(requestBody) {
    const apiUrl = "/v1/sales-reward-redeem";
    const response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/sales-reward-redeem/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v1/sales-reward-redeem";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(props) {
    let {rewardRedeemHistoryId, requestBody} = props;
    const apiUrl = `/v1/sales-reward-redeem/${rewardRedeemHistoryId}`;
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async setRewardToDeliver(props) {
    let {rewardRedeemHistoryId, requestBody} = props;
    let apiUrl = `/v1/sales-reward-redeem/${rewardRedeemHistoryId}/deliver`;
    let response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static getGpointExchangeStatus(status) {
    switch (status) {
      case "pending":
        return {text: "Menunggu Konfirmasi", color: "orange"};
      case "in_progress":
        return {text: "Diproses", color: "yellow"};
      case "cancelled":
        return {text: "Ditolak", color: "red"};
      case "in_delivery":
        return {text: "Dikirim", color: "blue"};
      case "completed":
        return {text: "Selesai", color: "green"};
      default:
        return {text: null, color: null};
    }
  }
}

export default gpointExchangeServices;
