import FetchInterceptor from "configs/FetchInterceptor";

class PromotionServices {
  static async getAll(reqBody) {
    let apiUrl = "/v2/promotion-group";
    let response = await FetchInterceptor.get(apiUrl, {params: reqBody});
    return response;
  }

  static async getByIds({promoIds}) {
    let apiUrl = `/v2/promotion-group/${promoIds}`;
    let response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(reqBody) {
    let apiUrl = "/v2/promotion-group";
    let response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async update(promoID, reqBody) {
    let apiUrl = `/v2/promotion-group/${promoID}`;
    let response = await FetchInterceptor.put(apiUrl, reqBody);
    return response;
  }

  static async getEligibility(reqBody) {
    const apiUrl = "/core/v2/discount/promo/get/eligibility";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }
}

export default PromotionServices;
