import FetchInterceptor from "configs/FetchInterceptor";

class BankServices {
  static async getAllBank(payload) {
    const response = await FetchInterceptor.post("/core/v1/banks/get/all", { params: payload });
    return response;
  }

  static async getAll(payload) {
    const urlApi = "/v1/banks";
    const response = await FetchInterceptor.get(urlApi, payload);
    return response;
  }
}

export default BankServices;
