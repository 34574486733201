import FetchInterceptor from "configs/FetchInterceptor";

class SalesBannerServices {
  static async add(requestBody) {
    let apiUrl = "/v1/event-banner";
    let response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addGroup(requestBody) {
    let apiUrl = "/v2/event-banner-group";
    let response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async update(requestBody) {
    let {bannerId, ...otherProps} = requestBody;
    let apiUrl = `/v1/event-banner/${bannerId}`;
    let response = await FetchInterceptor.put(apiUrl, otherProps);
    return response;
  }
  static async updateGroup(requestBody) {
    let {bannerId, ...otherProps} = requestBody;
    let apiUrl = `/v2/event-banner-group/${bannerId}`;
    let response = await FetchInterceptor.put(apiUrl, otherProps);
    return response;
  }
  static async delete(bannerId) {
    let apiUrl = `/v1/event-banner/${bannerId}`;
    let response = await FetchInterceptor.delete(apiUrl);
    return response;
  }
}

export default SalesBannerServices;
