import FetchInterceptor from "configs/FetchInterceptor";

class StockUnitsServices {
  static async getAll(requestBody) {
    const apiUrl = "/v1/units";
    const response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/units/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getByIds(requestBody) {
    const apiUrl = `/v1/units`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v1/units";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/v1/units";
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }
}

export default StockUnitsServices;
