// input 100000 -> output 100.000
export function numberToCurrency(input) {
  let value = typeof input === "string" ? input : input.toString();
  let formattedValue = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, (groupMatched) => {
    return `${groupMatched}.`;
  });
  return formattedValue;
}

// input 100.000 -> output 100000
export function currencyToNumber(input) {
  let textValue = input.replaceAll(".", "");
  let numberValue = Number(textValue);

  if (isNaN(numberValue)) {
    return textValue.slice(0, -1);
  }

  if (numberValue === 0) {
    return "";
  }
  return numberValue;
}
