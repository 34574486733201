import FetchInterceptor from "configs/FetchInterceptor";

class SalesInvoiceServices {
  static async getAll(requestBody) {
    const apiUrl = "/v1/sales-orders/";
    const response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/sales-orders/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async simulatesPoint(requestBody) {
    const apiUrl = "/v1/sales-orders";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default SalesInvoiceServices;
