import {all, takeEvery, put, fork, call} from "redux-saga/effects";
import {authContants, authenticated, showAuthMessage, signOutSuccess} from ".";
import {setStateUser, setDefaultUser} from "redux/actions/User";
import {SET_DEFAULT_USER} from "redux/constants/User";

import {EmployeesServices} from "services";

export function* signIn() {
  yield takeEvery(authContants.SIGNIN, function* ({payload}) {
    try {
      const user = yield call(EmployeesServices.signIn, payload);
      let stateUser = {
        refreshToken: {
          token: user.data.token,
        },
        employee: {
          ...user.data.employee,
        },
        role: {
          ...user.data.role,
        },
        accessMenus: {
          ...user.data.accessMenu,
        },
      };
      localStorage.setItem(authContants.ACTIVE_USER, user.data.employee.email);
      localStorage.setItem("token", user.data.token);
      yield put(setStateUser({currentUser: stateUser}));
      yield put(authenticated(user.data.employee.email));
    } catch (err) {
      if (err.response)
        yield put(showAuthMessage("Mohon periksa kembali email dan kata sandi Anda!"));
    }
  });
}

export function* signOut() {
  yield takeEvery(authContants.SIGNOUT, function* () {
    try {
      const userActive = localStorage.getItem(authContants.ACTIVE_USER);
      const requestBody = {email: userActive};
      // TODO : Integration logout after endpoint /logout ready
      // yield call(EmployeesServices.signOut, requestBody);
      localStorage.removeItem(authContants.ACTIVE_USER);
      localStorage.removeItem("token");
      yield put(setDefaultUser());
    } catch (err) {
      if (err.response) yield put(showAuthMessage("Mohon coba kembali!"));
    }
  });
}

export function* removeUserSession() {
  yield takeEvery(SET_DEFAULT_USER, function* () {
    try {
      yield put(signOutSuccess());
    } catch (err) {
      if (err.response) yield put(showAuthMessage("Mohon coba kembali!"));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signIn), fork(signOut), fork(removeUserSession)]);
}
