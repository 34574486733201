import React, {useEffect} from "react";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {BrowserRouter as Router} from "react-router-dom";
import {Route, Switch} from "react-router-dom";
import {ThemeSwitcherProvider} from "react-css-theme-switcher";

import {store, persistor} from "./redux/store/index";
import {THEME_CONFIG} from "./configs/AppConfig";
import Views from "./views";
import NotificationPopUp from "components/util-components/Notifications/NotificationPopUp";
import DevelopmentBox from "components/layout-components/DevelopmentBox";

const themes = {light: `${process.env.PUBLIC_URL}/css/light-theme.css`};

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      console.log = function () {};
    }
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <NotificationPopUp>
              <Router>
                <Switch>
                  <Route path="/" component={Views} />
                </Switch>
              </Router>
            </NotificationPopUp>
          </ThemeSwitcherProvider>
        </PersistGate>
      </Provider>
      <DevelopmentBox />
    </div>
  );
}

export default App;
