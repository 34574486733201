import FetchInterceptor from "configs/FetchInterceptor";

class EdcServices {
  static async getList(requestBody) {
    const apiUrl = "/core/v2/edc/list";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async search(requestBody) {
    const apiUrl = "/core/v2/edc/search";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v2/edc/create";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default EdcServices;
