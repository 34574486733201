import FetchInterceptor from "configs/FetchInterceptor";

class SupplierPaymentServices {
  static async getInvoice(requestBody) {
    const apiUrl = "/v1/invoices";
    const response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/supplier-payments/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(reqBody) {
    const apiUrl = "/v1/supplier-payments";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async pay(id, reqBody) {
    const apiUrl = `/v1/supplier-payments/${id}`;
    const response = await FetchInterceptor.put(apiUrl, reqBody);
    return response;
  }

  static async getMaker(branchId) {
    const apiUrl = `/v1/maker`;
    const response = await FetchInterceptor.get(apiUrl, { params: { branchId } });
    return response;
  }

  static async getAccount(payload) {
    const urlApi = "/v1/maker/accounts";
    const response = await FetchInterceptor.get(urlApi, { params: payload });
    return response;
  }
}

export default SupplierPaymentServices;
