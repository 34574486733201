import FetchInterceptor from "configs/FetchInterceptor";

class HistoryOfSellingTrasactionsPeritemServices {
  static async getAll(id, requestBody) {
    const apiUrl = `/core/v1/inventories/${id}/sales/history`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default HistoryOfSellingTrasactionsPeritemServices;
