import FetchInterceptor from "configs/FetchInterceptor";

class GiftServices {
  static async getAll(reqBody) {
    let apiUrl = "/v1/gifts";
    let response = await FetchInterceptor.get(apiUrl, { params: reqBody });
    return response;
  }

  static async getById(id) {
    let apiUrl = `/v1/gifts/${id}`;
    let response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(reqBody) {
    let apiUrl = "/v1/gifts";
    let response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async update(id, reqBody) {
    let apiUrl = `/v1/gifts/${id}`;
    let response = await FetchInterceptor.put(apiUrl, reqBody);
    return response;
  }
}

export default GiftServices;
