import FetchInterceptor from "configs/FetchInterceptor";

class CustomersServices {
  static async getAll(requestBody) {
    let apiUrl = "/v1/customers";
    let response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }

  static async getByIds(requestBody) {
    const apiUrl = "/core/v1/customers/get/data";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/customers/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/customers/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/v1/customers";
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async search(requestBody) {
    const apiUrl = "/v1/customers";
    const response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }

  static async addOptionNonMember(requestBody) {
    const apiUrl = "/core/v2/customer/nonmember/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async approve(id) {
    const apiUrl = `/v1/customers/approved/${id}`;
    const response = await FetchInterceptor.patch(apiUrl, {approved: "Approved"});
    return response;
  }

  static async reject(id) {
    const apiUrl = `/v1/customers/approved/${id}`;
    const response = await FetchInterceptor.patch(apiUrl, {approved: "Rejected"});
    return response;
  }
}

export default CustomersServices;
