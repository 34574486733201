import FetchInterceptor from "configs/FetchInterceptor";

class AreaServices {
  static async getAllProvinces() {
    const response = await FetchInterceptor.get("/v1/area/region");
    return response;
  }

  static async getAllCities(payload) {
    const response = await FetchInterceptor.get("/v1/area/city", { params: payload });
    return response;
  }

  static async getAllDistricts(payload) {
    const response = await FetchInterceptor.get("/v1/area/district", { params: payload });
    return response;
  }

  static async getAllSubDistricts(payload) {
    const response = await FetchInterceptor.get("/v1/area/subDistrict", { params: payload });
    return response;
  }

  static async getAllZipCodes(payload) {
    const response = await FetchInterceptor.get("/v1/area/postCode", { params: payload });
    return response;
  }
}

export default AreaServices;
