import FetchInterceptor from "configs/FetchInterceptor";

class ReceivableDownPaymentServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/sales/down-payment/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/sales/down-payment/create";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default ReceivableDownPaymentServices;
