import FetchInterceptor from "configs/FetchInterceptor";

class BankExpensesServices {
  static async getAll(params = {}) {
    const apiUrl = "/v1/cash-bank/history/BANK/OUT";
    const response = await FetchInterceptor.get(apiUrl, { params });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/cash-bank/history/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v1/cash-bank/BANK/OUT";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default BankExpensesServices;
