/* eslint-disable array-callback-return */
import React, {lazy, Suspense} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import _ from "lodash";

import Loading from "components/shared-components/Loading";
import {APP_PREFIX_PATH} from "configs/AppConfig";

export const AppViews = () => {
  let {accessMenus} = useSelector((state) => state.user);

  const listMenu = [
    {
      menu: "sidenav.system",
      path: `${APP_PREFIX_PATH}/system`,
      component: lazy(() => import(`./system`)),
    },
    {
      menu: "sidenav.master",
      path: `${APP_PREFIX_PATH}/master`,
      component: lazy(() => import(`./master`)),
    },
    {
      menu: "sidenav.stock",
      path: `${APP_PREFIX_PATH}/stock`,
      component: lazy(() => import(`./stock`)),
    },
    {
      menu: "sidenav.purchase",
      path: `${APP_PREFIX_PATH}/purchase`,
      component: lazy(() => import(`./purchase`)),
    },
    {
      menu: "sidenav.sales",
      path: `${APP_PREFIX_PATH}/sales`,
      component: lazy(() => import(`./sales`)),
    },
    {
      menu: "sidenav.reward",
      path: `${APP_PREFIX_PATH}/reward`,
      component: lazy(() => import(`./reward`)),
    },
    {
      menu: "sidenav.campaign",
      path: `${APP_PREFIX_PATH}/campaign`,
      component: lazy(() => import(`./campaign`)),
    },
    {
      menu: "sidenav.bankcash",
      path: `${APP_PREFIX_PATH}/bank-cash`,
      component: lazy(() => import(`./bank-cash`)),
    },
    {
      menu: "sidenav.bom",
      path: `${APP_PREFIX_PATH}/bill-of-material`,
      component: lazy(() => import(`./bill-of-material`)),
    },
    {
      menu: "sidenav.report",
      path: `${APP_PREFIX_PATH}/report`,
      component: lazy(() => import(`./report`)),
    },
    {
      menu: "sidenav.penetrationmap",
      path: `${APP_PREFIX_PATH}/penetration-map`,
      component: lazy(() => import(`./penetration-map`)),
    },
    {
      menu: "sidenav.salesapp",
      path: `${APP_PREFIX_PATH}/sales-app`,
      component: lazy(() => import(`./sales-app`)),
    },
    {
      menu: "sidenav.catalogmerchant",
      path: `${APP_PREFIX_PATH}/catalog-merchant`,
      component: lazy(() => import(`./catalog-merchant`)),
    },
    {
      menu: "sidenav.dashboard.salesCoordinator",
      path: `${APP_PREFIX_PATH}/sales-coordinator`,
      component: lazy(() => import(`./dashboard-sc`)),
    },
    {
      menu: "sidenav.approval",
      path: `${APP_PREFIX_PATH}/approval`,
      component: lazy(() => import(`./approval`)),
    },
    {
      menu: "sidenav.partners",
      path: `${APP_PREFIX_PATH}/partners`,
      component: lazy(() => import(`./partners`)),
    },
  ];

  const menus = _.chain(listMenu)
    .filter((item) => {
      let findMenu = _.find(accessMenus, (item2) => item2.menu === item.menu);
      if (findMenu) {
        return item;
      }
    })
    .map((item) => {
      let findMenu = _.find(accessMenus, (item2) => item2.menu === item.menu);
      return {...item, subMenu: findMenu.subMenu};
    })
    .value();

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        {menus.length &&
          menus.map(({path, component: ComponentWrapper, subMenu}, idx) => (
            <Route path={path} key={`app-route-${idx}`}>
              <ComponentWrapper subMenu={subMenu} />
            </Route>
          ))}
        <Route
          path={`${APP_PREFIX_PATH}/404`}
          component={lazy(() => import(`../auth-views/page-not-found`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
