import FetchInterceptor from "configs/FetchInterceptor";

class BranchServices {
  static async getAllBranch(payload) {
    let response = await FetchInterceptor.get("/v1/branches/", { params: payload });
    return response;
  }

  static async getBranch(id) {
    let response = await FetchInterceptor.get(`/v1/branches/${id}`);
    return response;
  }

  static async getAllBankAccount(payload) {
    const response = await FetchInterceptor.post("/core/v1/branches/bank-account/get/all", payload);
    return response;
  }

  static async addBranch(payload) {
    const response = await FetchInterceptor.post("/v1/branches", payload);
    return response;
  }

  static async updateBranch(payload) {
    const apiUrl = "/v1/branches";
    const response = await FetchInterceptor.put(apiUrl, payload);
    return response;
  }
}

export default BranchServices;
