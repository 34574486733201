import FetchInterceptor from "configs/FetchInterceptor";

class HistoryOfSellingPriceServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/selling-prices/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default HistoryOfSellingPriceServices;
