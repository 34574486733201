import React from "react";
import { notification } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { io } from "socket.io-client";

import { SHA256 } from "utils";

import { API_BASE_URL } from "constants/ApiConstant";

function NotificationPopUp({ children }) {
  let history = useHistory();

  const currentUser = useSelector((state) => state.user.currentUser);
  let branchId = currentUser ? currentUser.employee.branchId : null;
  let roleId = currentUser ? currentUser.employee.roleId : null;

  if (branchId && roleId) {
    let socket = io(API_BASE_URL, {
      path: "/notifications/socket.io",
    });

    let channel = SHA256(`merchant.${branchId}.${roleId}.notification`);

    socket.on(channel, function (props) {
      let { title, body } = props.message;
      let { section, refId } = props.data;

      // TODO : Put new notification on Redux

      notification.info({
        message: title,
        description: body,
        onClick: () => {
          if (section === "TRANSACTION") {
            history.push(`/app/sales/orders/${refId}`);
          } else if (section === "PURCHASE_RETURN") {
            history.push(`/app/purchase/return/${refId}`);
          } else if (section === "DELIVERY_REQUEST") {
            history.push(`/app/purchase/delivery-request/`);
          } else if (section === "REDEEM") {
            history.push(`/app/reward/history`);
          }
        },
      });
    });
  }

  return children;
}

export default NotificationPopUp;
