import FetchInterceptor from "configs/FetchInterceptor";

class DiscountServices {
  static async getAll(reqBody) {
    let apiUrl = "/v1/discounts";
    let response = await FetchInterceptor.get(apiUrl, {params: reqBody});
    return response;
  }

  static async getById({discountID}) {
    let apiUrl = `/v1/discounts/${discountID}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getByIds(reqBody) {
    const apiUrl = "/v1/discounts";
    const response = await FetchInterceptor.get(apiUrl, reqBody);
    return response;
  }

  static async add(reqBody) {
    let apiUrl = "/v2/discount-group";
    let response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async update({discountID, ...otherProps}) {
    const apiUrl = `/v2/discount-group/${discountID}`;
    const response = await FetchInterceptor.put(apiUrl, otherProps);
    return response;
  }

  static async getEligibility(reqBody) {
    const apiUrl = "/core/v2/discount/product/get/eligibility";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async updateInactive(goodId, groupIds) {
    const apiUrl = `/v2/discount-group/inactive/${goodId}`;
    const response = await FetchInterceptor.put(apiUrl, groupIds);
    return response;
  }
}

export default DiscountServices;
