import FetchInterceptor from "configs/FetchInterceptor";

class gcashRequestServices {
  static async getAll(params) {
    const apiUrl = "/v1/sales-cashout";
    const response = await FetchInterceptor.get(apiUrl, {params});
    return response;
  }

  static async update(id, requestBody) {
    const apiUrl = `/v1/sales-cashout/${id}`;
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async printPdf(reqBody) {
    let apiUrl = `/v1/sales-cashout/pdf`;
    let response = await FetchInterceptor.get(apiUrl, {params: reqBody, responseType: "blob"});
    return response;
  }
}

export default gcashRequestServices;
