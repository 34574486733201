import FetchInterceptor from "configs/FetchInterceptor";

class EmployeesServices {
  static async signIn(requestBody) {
    let apiUrl = "/v1/auth";
    let response = await FetchInterceptor.post(apiUrl, requestBody, {
      headers: {
        "public-request": "true",
      },
    });
    return response;
  }

  static async signOut(requestBody) {
    const apiUrl = "/core/v1/employees/logout";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllEmployee(payload) {
    const response = await FetchInterceptor.post("/core/v1/employees/get/all", payload);
    return response;
  }

  static async getEmployee(payload) {
    const response = await FetchInterceptor.get(`/core/v1/employees/get/${payload}`);
    return response;
  }

  static async addEmployee(payload) {
    const response = await FetchInterceptor.post("/v1/employees", payload);
    return response;
  }

  static async updateEmployee(payload) {
    const response = await FetchInterceptor.put(`/v1/employees`, payload);
    return response;
  }

  static async refreshToken(payload) {
    const response = await FetchInterceptor.post("/core/v1/employees/token/refresh", payload);
    return response;
  }
}

export default EmployeesServices;
