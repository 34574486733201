import FetchInterceptor from "configs/FetchInterceptor";

class SubBranchServices {
  static async getAllSubBranch(payload) {
    let response = await FetchInterceptor.get("/v1/markets/", {params: payload});
    return response;
  }

  static async getSubBranch(id) {
    let response = await FetchInterceptor.get(`/v1/markets/${id}`);
    return response;
  }
}

export default SubBranchServices;
