import FetchInterceptor from "configs/FetchInterceptor";

class SalesNotesServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/sales/notes/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = `/core/v1/sales/notes/add`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default SalesNotesServices;
