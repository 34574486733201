import FetchInterceptor from "configs/FetchInterceptor";

class StockOpnameServices {
  static async getAll(requestBody) {
    const apiUrl = "/v1/stock-opname";
    const response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/stock-opname/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getByIds(id) {
    const apiUrl = `/v1/stock-opname/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async create(requestBody) {
    const apiUrl = "/v1/stock-opname";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async cancel(requestBody) {
    const apiUrl = "/v1/stock-opname/cancel";
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async submit(requestBody) {
    const apiUrl = "/v1/stock-opname/submit";
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async printPdf(requestBody) {
    const apiUrl = "/v1/stock-opnames/print?";
    const response = await FetchInterceptor.get(apiUrl, {
      params: requestBody,
      responseType: "blob",
    });
    return response;
  }
}

export default StockOpnameServices;
