import FetchInterceptor from "configs/FetchInterceptor";

class RewardGoodsServices {
  static async getAll(requestBody) {
    const apiUrl = "/v1/rewards";
    const response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/rewards/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v1/rewards";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(id, requestBody) {
    const apiUrl = `/v1/rewards/${id}`;
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async updateStock(id, requestBody) {
    const apiUrl = `/v1/rewards/${id}/stockReward`;
    const response = await FetchInterceptor.patch(apiUrl, requestBody);
    return response;
  }
}

export default RewardGoodsServices;
