import FetchInterceptor from "configs/FetchInterceptor";

class RolesServices {
  static async getAllRole(payload) {
    let response = await FetchInterceptor.get("/v1/roles", { params: payload });
    return response;
  }

  static async getRoleById(roleId) {
    let response = await FetchInterceptor.get(`/v1/roles/${roleId}`);
    return response;
  }

  static async addRole(payload) {
    let response = await FetchInterceptor.post("/v1/roles", payload);
    return response;
  }

  static async updateRole({ roleId, ...payload }) {
    let response = await FetchInterceptor.put(`/v1/roles/${roleId}`, { ...payload, roleId });
    return response;
  }
}

export default RolesServices;
