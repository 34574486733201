import CryptoJS from "crypto-js";

export const IS_DEVELOPMENT = process.env.REACT_APP_ENVIRONMENT === "development";
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID ?? "";
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET ?? "";
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY ?? "";

export const sg = () => {
  let currentDate = new Date();
  let day = currentDate.toUTCString("en-us", {weekday: "short"}).substring(0, 3);
  let date = currentDate.getUTCDate();
  let month = currentDate.toUTCString("en-us", {weekday: "short"}).substring(11, 8);
  let year = currentDate.getUTCFullYear();
  let dateString = `${day} ${month} ${date} ${year}`;

  let base64Hash = CryptoJS.enc.Utf8.parse(CLIENT_ID + ":" + CLIENT_SECRET);
  let base64 = CryptoJS.enc.Base64.stringify(base64Hash);

  let signature = CryptoJS.HmacSHA512(base64 + "," + dateString, CLIENT_SECRET).toString();

  return signature;
};
