import FetchInterceptor from "configs/FetchInterceptor";

class SellingPricesServices {
  static async getAll(requestBody) {
    const apiUrl = "/v2/selling-prices";
    const response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v2/selling-prices";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(id, requestBody) {
    const apiUrl = `/v1/selling-prices/${id}`;
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async getById(id, requestBody) {
    const apiUrl = `/v2/selling-prices/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }
}

export default SellingPricesServices;
