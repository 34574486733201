import FetchInterceptor from "configs/FetchInterceptor";

class StockCategoryServices {
  static async getAll(requestBody) {
    const apiUrl = "/v1/categories";
    const response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v1/categories";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(id, requestBody) {
    const apiUrl = `/v1/categories/${id}`;
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async uploadImage(requestBody) {
    const apiUrl = "/v1/images/upload";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(requestBody) {
    const apiUrl = "/v1/categories";
    const response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }
}

export default StockCategoryServices;
