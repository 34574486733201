import FetchInterceptor from "configs/FetchInterceptor";

class RewardGoodsPurchaseServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/reward/purchase-history/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/reward/purchase-history/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/reward/purchase-history/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/core/v1/reward/purchase-history/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default RewardGoodsPurchaseServices;
