import FetchInterceptor from "configs/FetchInterceptor";

class PaymentServices {
  static async getAll(payload) {
    const apiUrl = "/v1/payments";
    const response = await FetchInterceptor.get(apiUrl, payload);
    return response;
  }

  static async getCustomerGroup(payload) {
    const apiUrl = "/v1/customer-groups";
    const response = await FetchInterceptor.get(apiUrl, payload);
    return response;
  }
}

export default PaymentServices;
