import FetchInterceptor from "configs/FetchInterceptor";

class AccessMenuServices {
  static async getAll() {
    let response = await FetchInterceptor.get("/v1/access-menus");
    return response;
  }
}

export default AccessMenuServices;
