/* eslint-disable array-callback-return */
import React from "react";
import {Link} from "react-router-dom";
import {Menu, Grid} from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import {connect} from "react-redux";
import {SIDE_NAV_LIGHT, NAV_TYPE_SIDE} from "constants/ThemeConstant";
import utils from "utils";
import {onMobileNavToggle} from "redux/actions/Theme";
import {useSelector} from "react-redux";
import _ from "lodash";

const {SubMenu} = Menu;
const {useBreakpoint} = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    let arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      let elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  let {sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle} = props;
  let isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  let closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  let {accessMenus} = useSelector((state) => state.user);
  let settingsNavigation = _.chain(navigationConfig)
    .filter((item) => {
      let findMenu = _.find(accessMenus, (item2) => item2.menu === item.title);
      if (findMenu) {
        return item;
      }
    })
    .map((item3) => {
      return {
        ...item3,
        submenu: _.filter(item3.submenu, (item4) => {
          let findMenu = _.find(accessMenus, (item2) => item2.menu === item3.title);
          let findSubMenu = _.find(findMenu.subMenu, (item5) => item5.menu === item4.title);
          if (findSubMenu) {
            return item4;
          }
        }),
      };
    })
    .value();

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{height: "100%", borderRight: 0}}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {settingsNavigation.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            icon={menu.icon ? <Icon type={menu?.icon} /> : null}
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {menu.submenu.map((subMenuFirst) => (
              <Menu.Item key={subMenuFirst.key}>
                {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                <span>{setLocale(localization, subMenuFirst.title)}</span>
                <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  let {topNavColor, localization} = props;
  let {accessMenus} = useSelector((state) => state.user);
  let settingsNavigation = _.chain(navigationConfig)
    .filter((item) => {
      let findMenu = _.find(accessMenus, (item2) => item2.menu === item.title);
      if (findMenu) {
        return item;
      }
    })
    .map((item3) => {
      return {
        ...item3,
        submenu: _.filter(item3.submenu, (item4) => {
          let findMenu = _.find(accessMenus, (item2) => item2.menu === item3.title);
          let findSubMenu = _.find(findMenu.subMenu, (item5) => item5.menu === item4.title);
          if (findSubMenu) {
            return item4;
          }
        }),
      };
    })
    .value();
  return (
    <Menu mode="horizontal" style={{backgroundColor: topNavColor}}>
      {settingsNavigation.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>{setLocale(localization, subMenuSecond.title)}</span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              ),
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({theme}) => {
  let {sideNavTheme, topNavColor} = theme;
  return {sideNavTheme, topNavColor};
};

export default connect(mapStateToProps, {onMobileNavToggle})(MenuContent);
