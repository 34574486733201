/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import {IntlProvider} from "react-intl";
import {ConfigProvider} from "antd";
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";

function RouteInterceptor({children, isFirstLogin, isAuthenticated, ...rest}) {
  return (
    <Route
      {...rest}
      render={({location}) =>
        isAuthenticated ? (
          !isFirstLogin ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: `${AUTH_PREFIX_PATH}/change-password`,
                state: {from: location},
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: {from: location},
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  let {locale, location, direction, activeUser, currentUser} = props;
  let currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor
            path={APP_PREFIX_PATH}
            isAuthenticated={activeUser && currentUser}
            isFirstLogin={currentUser && currentUser.employee.is_first_login}
          >
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({theme, auth, user}) => {
  let {locale, direction} = theme;
  let {activeUser} = auth;
  let {currentUser} = user;
  return {locale, direction, activeUser, currentUser};
};

export default withRouter(connect(mapStateToProps)(Views));
