import FetchInterceptor from "configs/FetchInterceptor";

class GoodsServices {
  static async getGlobal(requestBody) {
    const apiUrl = "/v1/global/products";
    const response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }
  static async getProduct(reqBody) {
    const apiUrl = "/v1/global/products";
    const response = await FetchInterceptor.get(apiUrl, {params: reqBody});
    return response;
  }

  static async getById(productId) {
    const apiUrl = `/v1/products/${productId}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getByIds(id, requestBody) {
    const apiUrl = `/v1/products/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v1/products";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/v1/products";
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async uploadImage(requestBody) {
    const apiUrl = "/v1/images/upload";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async goodUnitByGoodId(goodId) {
    const apiUrl = `/v1/products/${goodId}/units`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async stockByWarehouse(requestBody) {
    const apiUrl = "/v1/stocks";
    const response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }
}

export default GoodsServices;
