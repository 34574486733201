import FetchInterceptor from "configs/FetchInterceptor";

class RewardHistoryServices {
  static async getAll(requestBody) {
    const apiUrl = "/v1/rewards-history";
    const response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/rewards-history${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v1/rewards-history";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/v1/rewards-history";
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async getTokenReward(requestBody) {
    let apiUrl = "/v1/rewards-history/token";
    let response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default RewardHistoryServices;
