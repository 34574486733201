import FetchInterceptor from "configs/FetchInterceptor";

class InventoriesServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/inventories/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllV2(requestBody) {
    let apiUrl = "/v1/inventories";
    let response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }

  static async getByIds(requestBody) {
    const apiUrl = "/core/v1/inventories/get/data";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getByIdsV2(requestBody) {
    const apiUrl = "/core/v2/inventories/booking/get/data";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/inventories/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default InventoriesServices;
