import {message} from "antd";
import FetchInterceptor from "configs/FetchInterceptor";

class ClosingHourServices {
  static async getServerTime() {
    const apiUrl = "/v1/time";
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getClosingHour() {
    const apiUrl = "/v1/closing-time";
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async set(requestBody) {
    const apiUrl = "/v1/closing-time";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getClosingHourData() {
    try {
      let currentTimeData = await FetchInterceptor.get("/v1/time");
      let closingTimeData = await FetchInterceptor.get("/v1/closing-time");

      let currentDateTime = new Date(
        `${currentTimeData?.data?.date} ${currentTimeData?.data?.time}`,
      );
      let startDateTime = new Date(
        `${currentTimeData?.data?.date} ${closingTimeData?.data?.closingTimeStart}`,
      );
      let endDateTime = new Date(
        `${currentTimeData?.data?.date} ${closingTimeData?.data?.closingTimeEnd}`,
      );
      endDateTime.setDate(endDateTime.getDate() + 1);

      return {
        closingTime: closingTimeData?.data?.closingTimeStart,
        openingTime: closingTimeData?.data?.closingTimeEnd,
        isClosingTime: currentDateTime > startDateTime && currentDateTime < endDateTime,
      };
    } catch (error) {
      message.error(`Terjadi kesalahan : ${error.message}`);
    }
  }
}

export default ClosingHourServices;
