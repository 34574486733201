import FetchInterceptor from "configs/FetchInterceptor";

class StockMutationServices {
  static async getAll(requestBody) {
    const apiUrl = "/v1/mutations";
    const response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/mutations/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async addInternalMutations(requestBody) {
    const apiUrl = "/v1/mutations/";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async submitMutations(requestBody) {
    const apiUrl = "/v1/mutations-confirm";
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async cancelledMutations(id) {
    const apiUrl = `/v1/mutations-cancel/${id}`;
    const response = await FetchInterceptor.put(apiUrl);
    return response;
  }
}

export default StockMutationServices;
