import FetchInterceptor from "configs/FetchInterceptor";

class SalesReturnsServices {
  static async getAll(requestBody) {
    const apiUrl = "/v1/sales-order/return";
    const response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/sales-order/return/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v1/sales-order/return/create";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/core/v1/sales/orders/returns/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async process(requestBody) {
    const apiUrl = "/core/v1/sales/orders/returns/process";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default SalesReturnsServices;
