import { SIDE_NAV_LIGHT, NAV_TYPE_TOP, DIR_LTR } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Grosirone Merchant";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "id",
  navType: NAV_TYPE_TOP,
  topNavColor: "#ffffff",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
};
