import FetchInterceptor from "configs/FetchInterceptor";

class DoWarehouseServices {
  static async getList(requestBody) {
    const apiUrl = "/core/v2/warehouse/delivery/list";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async printStatus(requestBody) {
    const apiUrl = "/core/v2/warehouse/delivery/print/status";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v1/sales/invoices/get/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }
}

export default DoWarehouseServices;
