import FetchInterceptor from "configs/FetchInterceptor";

class PurchaseReturnsServices {
  static async getAll(requestBody) {
    const apiUrl = "/v1/purchase-returns";
    const response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }
  static async getById(id) {
    const apiUrl = `/v1/purchase-returns/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }
  static async add(requestBody) {
    const apiUrl = "/v1/purchase-returns";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async update(requestBody) {
    const apiUrl = "/v1/purchase-returns";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async approve(id, reqBody) {
    const apiUrl = `/v1/purchase-returns/${id}/approve`;
    const response = await FetchInterceptor.patch(apiUrl, reqBody);
    return response;
  }
  static async reject(id, reqBody) {
    const apiUrl = `/v1/purchase-returns/${id}/reject`;
    const response = await FetchInterceptor.patch(apiUrl, reqBody);
    return response;
  }
  static async close(id, reqBody) {
    const apiUrl = `/v1/purchase-returns/${id}/close`;
    const response = await FetchInterceptor.patch(apiUrl, reqBody);
    return response;
  }
  static async getPdf(id) {
    const apiUrl = `/core/v1/purchase/invoice/return/get/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }
}

export default PurchaseReturnsServices;
