import FetchInterceptor from "configs/FetchInterceptor";

class SalesRewardServices {
  static async add(requestBody) {
    const apiUrl = "/v1/sales-reward";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAll(requestBody) {
    const apiUrl = "/v1/sales-reward";
    const response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/sales-reward/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }
  static async update(id, requestBody) {
    const apiUrl = `/v1/sales-reward/${id}`;
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }
}

export default SalesRewardServices;
