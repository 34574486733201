import FetchInterceptor from "configs/FetchInterceptor";

class StockCorrectionServices {
  static async getAll(requestBody) {
    const apiUrl = `/v1/stock-corrections`;
    const response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v1/stock-corrections";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllStockIn(requestBody) {
    const apiUrl = "/core/v1/stock-in/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getPdf(requestBody) {
    const apiUrl = `/v1/stock-corrections/print`;
    const response = await FetchInterceptor.get(apiUrl, {
      params: requestBody,
      responseType: "blob",
    });
    return response;
  }
}

export default StockCorrectionServices;
