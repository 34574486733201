import FetchInterceptor from "configs/FetchInterceptor";

class SupplierServices {
  static async getAll(requestBody) {
    const apiUrl = "/v1/suppliers";
    const response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v1/suppliers";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/v1/suppliers";
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async getByIds(requestBody) {
    const apiUrl = "/core/v1/suppliers/get/data";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/suppliers/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getAllContact(requestBody) {
    const apiUrl = "/core/v1/suppliers/contact/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getContactById(id) {
    const apiUrl = `/core/v1/suppliers/contact/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async addContact(requestBody) {
    const apiUrl = "/core/v1/suppliers/contact/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async updateContact(requestBody) {
    const apiUrl = "/core/v1/suppliers/contact/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default SupplierServices;
