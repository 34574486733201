import {
  AppstoreAddOutlined,
  ApartmentOutlined,
  FileDoneOutlined,
  NotificationOutlined,
  SelectOutlined,
  ExportOutlined,
  AuditOutlined,
  BankOutlined,
  FilePdfOutlined,
  GiftOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  ShopOutlined,
  FileProtectOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import {APP_PREFIX_PATH} from "configs/AppConfig";

const systemNavTree = [
  {
    key: "system-nav",
    path: `${APP_PREFIX_PATH}/system`,
    title: "sidenav.system",
    icon: ApartmentOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "system-setupuser",
        path: `${APP_PREFIX_PATH}/system/setup-user`,
        title: "sidenav.system.setupuser",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "system-closingperiode",
        path: `${APP_PREFIX_PATH}/system/closing-periode`,
        title: "sidenav.system.closingperiode",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "system-restoreperiode",
        path: `${APP_PREFIX_PATH}/system/restore-periode`,
        title: "sidenav.system.restoreperiode",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "system-hpprecalculation",
        path: `${APP_PREFIX_PATH}/system/hpp-recalculation`,
        title: "sidenav.system.hpprecalculation",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sidenav.system.uploadlogo",
        path: `${APP_PREFIX_PATH}/system/upload-logo`,
        title: "sidenav.system.uploadlogo",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sidenav.system.verificationuser",
        path: `${APP_PREFIX_PATH}/system/verification-user`,
        title: "sidenav.system.verificationuser",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sidenav.system.verificationuserpos",
        path: `${APP_PREFIX_PATH}/system/verification-user-pos`,
        title: "sidenav.system.verificationuserpos",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sidenav.system.contentmanagement",
        path: `${APP_PREFIX_PATH}/system/content-management`,
        title: "sidenav.system.contentmanagement",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sidenav.system.mappinghoa",
        path: `${APP_PREFIX_PATH}/system/mapping-hoa`,
        title: "sidenav.system.mappinghoa",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sidenav.system.mappingvpsales",
        path: `${APP_PREFIX_PATH}/system/mapping-vp-sales`,
        title: "sidenav.system.mappingvpsales",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const masterNavTree = [
  {
    key: "master-nav",
    path: `${APP_PREFIX_PATH}/master`,
    title: "sidenav.master",
    icon: AppstoreAddOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "coa",
        path: `${APP_PREFIX_PATH}/master/chart-of-account`,
        title: "sidenav.master.coa",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "paymentlist",
        path: `${APP_PREFIX_PATH}/master/payment`,
        title: "sidenav.master.payment",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "branch",
        path: `${APP_PREFIX_PATH}/master/branch`,
        title: "sidenav.master.branch",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "subbranch",
        path: `${APP_PREFIX_PATH}/master/sub-branch`,
        title: "sidenav.master.subbranch",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "greetingmessage",
        path: `${APP_PREFIX_PATH}/master/greeting-message`,
        title: "sidenav.master.greetingmessage",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stock",
        path: `${APP_PREFIX_PATH}/master/stock`,
        title: "sidenav.master.stock",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stockunit",
        path: `${APP_PREFIX_PATH}/master/stock-unit`,
        title: "sidenav.master.stockunit",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "brand",
        path: `${APP_PREFIX_PATH}/master/brand`,
        title: "sidenav.master.brand",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "supplier",
        path: `${APP_PREFIX_PATH}/master/supplier`,
        title: "sidenav.master.supplier",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "customer",
        path: `${APP_PREFIX_PATH}/master/customer`,
        title: "sidenav.master.customer",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "warehouse",
        path: `${APP_PREFIX_PATH}/master/warehouse`,
        title: "sidenav.master.warehouse",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "master-edc",
        path: `${APP_PREFIX_PATH}/master/master-edc`,
        title: "sidenav.master.masteredc",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "change-selling-price",
        path: `${APP_PREFIX_PATH}/master/changes-selling-price`,
        title: "sidenav.master.changesellingprice",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "margin-stokies",
        path: `${APP_PREFIX_PATH}/master/margin-stokies`,
        title: "sidenav.master.marginstokies",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "banner",
        path: `${APP_PREFIX_PATH}/master/banner`,
        title: "sidenav.master.banner",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "grouping-stockist",
        path: `${APP_PREFIX_PATH}/master/grouping-stockist`,
        title: "sidenav.master.groupingstockist",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "survey-stockist",
        path: `${APP_PREFIX_PATH}/master/survey-stockist`,
        title: "sidenav.master.surveystockist",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "point-product",
        path: `${APP_PREFIX_PATH}/master/product-point`,
        title: "sidenav.master.pointproduct",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "motorist",
        path: `${APP_PREFIX_PATH}/master/motorist`,
        title: "sidenav.master.motoris",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "banner-motorist",
        path: `${APP_PREFIX_PATH}/master/banner-motorist`,
        title: "sidenav.master.motorisbanner",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "community-product",
        path: `${APP_PREFIX_PATH}/master/community-product`,
        title: "sidenav.master.communityproduct",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "approval-master",
        path: `${APP_PREFIX_PATH}/master/approval`,
        title: "sidenav.master.approval",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "merchant-tag",
        path: `${APP_PREFIX_PATH}/master/merchant-tag`,
        title: "sidenav.master.merchanttag",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const stockNavTree = [
  {
    key: "stock-nav",
    path: `${APP_PREFIX_PATH}/stock`,
    title: "sidenav.stock",
    icon: FileDoneOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "stock-stockinjection",
        path: `${APP_PREFIX_PATH}/stock/injection`,
        title: "sidenav.stock.stockinjection",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stock-stockmutation",
        path: `${APP_PREFIX_PATH}/stock/mutation`,
        title: "sidenav.stock.stockmutation",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stock-stockmutationbybranch",
        path: `${APP_PREFIX_PATH}/stock/mutation-by-branch`,
        title: "sidenav.stock.stockmutationbybranch",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stock-stockopname",
        path: `${APP_PREFIX_PATH}/stock/opname`,
        title: "sidenav.stock.stockopname",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stock-stockcorrection",
        path: `${APP_PREFIX_PATH}/stock/correction`,
        title: "sidenav.stock.stockcorrection",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stock-limitstock",
        path: `${APP_PREFIX_PATH}/stock/limit`,
        title: "sidenav.stock.limitstock",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const purchaseNavTree = [
  {
    key: "purchase-nav",
    path: `${APP_PREFIX_PATH}/purchase`,
    title: "sidenav.purchase",
    icon: SelectOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "purchase-purchaseorder",
        path: `${APP_PREFIX_PATH}/purchase/purchase-order`,
        title: "sidenav.purchase.purchaseorder",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-goodsreceipt",
        path: `${APP_PREFIX_PATH}/purchase/receive-order`,
        title: "sidenav.purchase.goodsreceipt",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-invoice",
        path: `${APP_PREFIX_PATH}/purchase/invoice`,
        title: "sidenav.purchase.invoice",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-debitnote",
        path: `${APP_PREFIX_PATH}/purchase/debit-note`,
        title: "sidenav.purchase.debitnote",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-creditnote",
        path: `${APP_PREFIX_PATH}/purchase/credit-note`,
        title: "sidenav.purchase.creditnote",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-others",
        path: `${APP_PREFIX_PATH}/purchase/other-debt`,
        title: "sidenav.purchase.others",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-debtdownpayment",
        path: `${APP_PREFIX_PATH}/purchase/debt-down-payment`,
        title: "sidenav.purchase.debtdownpayment",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-supplierreceipt",
        path: `${APP_PREFIX_PATH}/purchase/supplier-receipt`,
        title: "sidenav.purchase.supplierreceipt",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-debtrepayment",
        path: `${APP_PREFIX_PATH}/purchase/debt-repayment`,
        title: "sidenav.purchase.debtrepayment",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-purchasepricehistory",
        path: `${APP_PREFIX_PATH}/purchase/purchase-price-history`,
        title: "sidenav.purchase.purchasepricehistory",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sidenav.purchase.deliveryrequest",
        path: `${APP_PREFIX_PATH}/purchase/delivery-request`,
        title: "sidenav.purchase.deliveryrequest",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-periodicdeliveryrequest",
        path: `${APP_PREFIX_PATH}/purchase/periodic-delivery-request`,
        title: "sidenav.purchase.periodicdeliveryrequest",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-deliveryrequestschedule",
        path: `${APP_PREFIX_PATH}/purchase/delivery-request-schedule`,
        title: "sidenav.purchase.drs",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-deliveryList",
        path: `${APP_PREFIX_PATH}/purchase/delivery-list`,
        title: "sidenav.purchase.deliverylist",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-invoiceapproval",
        path: `${APP_PREFIX_PATH}/purchase/invoice-approval`,
        title: "sidenav.purchase.invoiceapproval",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-stockarrivaldelivery",
        path: `${APP_PREFIX_PATH}/purchase/stock-arrival-delivery`,
        title: "sidenav.purchase.stockarrivaldelivery",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-stockarrivalstockist",
        path: `${APP_PREFIX_PATH}/purchase/stock-arrival-stockist`,
        title: "sidenav.purchase.stockarrivalstockist",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-invoicesettlement",
        path: `${APP_PREFIX_PATH}/purchase/invoice-settlement`,
        title: "sidenav.purchase.invoicesettlement",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-return",
        path: `${APP_PREFIX_PATH}/purchase/return`,
        title: "sidenav.purchase.return",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const salesNavTree = [
  {
    key: "sales-nav",
    path: `${APP_PREFIX_PATH}/sales`,
    title: "sidenav.sales",
    icon: ExportOutlined,
    breadcrumb: false,
    submenu: [
      // {
      //   key: "sales-booking",
      //   path: `${APP_PREFIX_PATH}/sales/booking`,
      //   title: "sidenav.sales.booking",
      //   icon: "",
      //   breadcrumb: true,
      //   submenu: [],
      // },
      {
        key: "sales-cashier",
        path: `${APP_PREFIX_PATH}/sales/cashier`,
        title: "sidenav.sales.cashier",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-orders",
        path: `${APP_PREFIX_PATH}/sales/orders`,
        title: "sidenav.sales.orders",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-dowarehouse",
        path: `${APP_PREFIX_PATH}/sales/do-warehouse`,
        title: "sidenav.sales.dowarehouse",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-opencod",
        path: `${APP_PREFIX_PATH}/sales/open-cod`,
        title: "sidenav.sales.opencod",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-outerwarehousemutation",
        path: `${APP_PREFIX_PATH}/sales/outer-warehouse-mutation`,
        title: "sidenav.sales.outerwarehousemutation",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-outerwarehousemutationreturns",
        path: `${APP_PREFIX_PATH}/sales/outer-warehouse-mutation-returns`,
        title: "sidenav.sales.outerwarehousemutationreturns",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      // {
      //   key: "sales-salesinvoice",
      //   path: `${APP_PREFIX_PATH}/sales/sales-invoice`,
      //   title: "sidenav.sales.salesinvoice",
      //   icon: "",
      //   breadcrumb: true,
      //   submenu: [],
      // },
      {
        key: "sales-salesreturns",
        path: `${APP_PREFIX_PATH}/sales/sales-returns`,
        title: "sidenav.sales.salesreturns",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-salesdebitnotes",
        path: `${APP_PREFIX_PATH}/sales/sales-debit-notes`,
        title: "sidenav.sales.salesdebitnotes",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-salescreditnotes",
        path: `${APP_PREFIX_PATH}/sales/sales-credit-notes`,
        title: "sidenav.sales.salescreditnotes",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      // {
      //   key: "sales-receivabledownpayment",
      //   path: `${APP_PREFIX_PATH}/sales/receivable-down-payment`,
      //   title: "sidenav.sales.receivabledownpayment",
      //   icon: "",
      //   breadcrumb: true,
      //   submenu: [],
      // },
      // {
      //   key: "sales-repaymentofreceivables",
      //   path: `${APP_PREFIX_PATH}/sales/repayment-of-receivables`,
      //   title: "sidenav.sales.repaymentofreceivables",
      //   icon: "",
      //   breadcrumb: true,
      //   submenu: [],
      // },
      // {
      //   key: "sales-historyofsellingprice",
      //   path: `${APP_PREFIX_PATH}/sales/history-of-selling-price`,
      //   title: "sidenav.sales.historyofsellingprice",
      //   icon: "",
      //   breadcrumb: true,
      //   submenu: [],
      // },
      {
        key: "sales-historyofsellingtrasactionsperitem",
        path: `${APP_PREFIX_PATH}/sales/history-of-selling-trasactions-peritem`,
        title: "sidenav.sales.historyofsellingtrasactionsperitem",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const rewardNavTree = [
  {
    key: "reward-nav",
    path: `${APP_PREFIX_PATH}/reward`,
    title: "sidenav.reward",
    icon: GiftOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "reward.goods",
        path: `${APP_PREFIX_PATH}/reward/goods`,
        title: "sidenav.reward.goods",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "reward.history",
        path: `${APP_PREFIX_PATH}/reward/history`,
        title: "sidenav.reward.history",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "reward.goodspurchase",
        path: `${APP_PREFIX_PATH}/reward/goods-purchase`,
        title: "sidenav.reward.goodspurchase",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const bomNavTree = [
  {
    key: "bom-nav",
    path: `${APP_PREFIX_PATH}/bill-of-material`,
    title: "sidenav.bom",
    icon: AuditOutlined,
    breadcrumb: true,
    submenu: [],
  },
];

const campaignNavTree = [
  {
    key: "campaign-nav",
    path: `${APP_PREFIX_PATH}/campaign`,
    title: "sidenav.campaign",
    icon: NotificationOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "campaign.gift",
        path: `${APP_PREFIX_PATH}/campaign/gift`,
        title: "sidenav.campaign.gift",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "campaign.discount",
        path: `${APP_PREFIX_PATH}/campaign/discount`,
        title: "sidenav.campaign.discount",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "campaign.promotion",
        path: `${APP_PREFIX_PATH}/campaign/promotion`,
        title: "sidenav.campaign.promotion",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "campaign.discountbystockist",
        path: `${APP_PREFIX_PATH}/campaign/discount-by-stockist`,
        title: "sidenav.campaign.discountbystockist",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "campaign.promotionbystockist",
        path: `${APP_PREFIX_PATH}/campaign/promotion-by-stockist`,
        title: "sidenav.campaign.promotionbystockist",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const bankcashNavTree = [
  {
    key: "bankcash-nav",
    path: `${APP_PREFIX_PATH}/bank-cash`,
    title: "sidenav.bankcash",
    icon: BankOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "bankcash-cashreceipts",
        path: `${APP_PREFIX_PATH}/bank-cash/cash-receipts`,
        title: "sidenav.bankcash.cashreceipts",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-cashexpenses",
        path: `${APP_PREFIX_PATH}/bank-cash/cash-expenses`,
        title: "sidenav.bankcash.cashexpenses",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-bankreceipts",
        path: `${APP_PREFIX_PATH}/bank-cash/bank-receipts`,
        title: "sidenav.bankcash.bankreceipts",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-bankexpenses",
        path: `${APP_PREFIX_PATH}/bank-cash/bank-expenses`,
        title: "sidenav.bankcash.bankexpenses",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-generaljournal",
        path: `${APP_PREFIX_PATH}/bank-cash/general-journal`,
        title: "sidenav.bankcash.generaljournal",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-journalofadjustments",
        path: `${APP_PREFIX_PATH}/bank-cash/adjustment-journal`,
        title: "sidenav.bankcash.journalofadjustments",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      // {
      //   key: "bankcash-debtliquidbgprocess",
      //   path: `${APP_PREFIX_PATH}/bank-cash/debt-liquid-bg-process`,
      //   title: "sidenav.bankcash.debtliquidbgprocess",
      //   icon: "",
      //   breadcrumb: true,
      //   submenu: [],
      // },
      // {
      //   key: "bankcash-processbgliquidreceivables",
      //   path: `${APP_PREFIX_PATH}/bank-cash/process-bg-liquid-receivables`,
      //   title: "sidenav.bankcash.processbgliquidreceivables",
      //   icon: "",
      //   breadcrumb: true,
      //   submenu: [],
      // },
    ],
  },
];

const reportNavTree = [
  {
    key: "report-nav",
    path: `${APP_PREFIX_PATH}/report`,
    title: "sidenav.report",
    icon: FilePdfOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "report-stockreport",
        path: `${APP_PREFIX_PATH}/report/stock-report`,
        title: "sidenav.report.stockreport",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-purchasereport",
        path: `${APP_PREFIX_PATH}/report/purchase-report`,
        title: "sidenav.report.purchasereport",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-financialstatements",
        path: `${APP_PREFIX_PATH}/report/financial-statements`,
        title: "sidenav.report.financialstatements",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-purchaseanalysis",
        path: `${APP_PREFIX_PATH}/report/purchase-analysis`,
        title: "sidenav.report.purchaseanalysis",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-salesanalysis",
        path: `${APP_PREFIX_PATH}/report/sales-analysis`,
        title: "sidenav.report.salesanalysis",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-salesdashboard",
        path: `${APP_PREFIX_PATH}/report/sales-dashboard`,
        title: "sidenav.report.salesdashboard",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-salesreportpercashier",
        path: `${APP_PREFIX_PATH}/report/sales-report-percashier`,
        title: "sidenav.report.salesreportpercashier",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-stockageanalysis",
        path: `${APP_PREFIX_PATH}/report/stock-age-analysis`,
        title: "sidenav.report.stockageanalysis",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-dailyreport",
        path: `${APP_PREFIX_PATH}/report/daily-report`,
        title: "sidenav.report.dailyreport",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-campaignaward",
        path: `${APP_PREFIX_PATH}/report/campaign-award`,
        title: "sidenav.report.campaignaward",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-sales",
        path: `${APP_PREFIX_PATH}/report/sales-report`,
        title: "sidenav.report.sales",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const penetrationMapNavTree = [
  {
    key: "penetrationmap-nav",
    path: `${APP_PREFIX_PATH}/penetration-map`,
    title: "sidenav.penetrationmap",
    icon: EnvironmentOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const catalogMerchant = [
  {
    key: "catalog-merchant",
    path: `${APP_PREFIX_PATH}/catalog-merchant`,
    title: "sidenav.catalogmerchant",
    icon: ShopOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const salesAppNavTree = [
  {
    key: "sales-app-nav",
    path: `${APP_PREFIX_PATH}/sales-app`,
    title: "sidenav.salesapp",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "sales-app-scmapping",
        path: `${APP_PREFIX_PATH}/sales-app/sc-mapping`,
        title: "sidenav.salesapp.scmapping",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-eventbanner",
        path: `${APP_PREFIX_PATH}/sales-app/event-banner`,
        title: "sidenav.salesapp.bannerevent",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-sarewards",
        path: `${APP_PREFIX_PATH}/sales-app/sa-reward`,
        title: "sidenav.salesapp.sarewards",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-gcashrequest",
        path: `${APP_PREFIX_PATH}/sales-app/gcash-request`,
        title: "sidenav.salesapp.gcashrequest",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-gpointrequest",
        path: `${APP_PREFIX_PATH}/sales-app/gpoint-exchange`,
        title: "sidenav.salesapp.gpointrequest",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-dailyproducttarget",
        path: `${APP_PREFIX_PATH}/sales-app/daily-product-target`,
        title: "sidenav.salesapp.dailyproducttarget",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-monthlyproducttarget",
        path: `${APP_PREFIX_PATH}/sales-app/monthly-product-target`,
        title: "sidenav.salesapp.monthlyproducttarget",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-dailyperformancetarget",
        path: `${APP_PREFIX_PATH}/sales-app/daily-performance-target`,
        title: "sidenav.salesapp.dailyperformancetarget",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-monthlyperformancetarget",
        path: `${APP_PREFIX_PATH}/sales-app/monthly-performance-target`,
        title: "sidenav.salesapp.monthlyperformancetarget",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-closinghour",
        path: `${APP_PREFIX_PATH}/sales-app/closing-hour`,
        title: "sidenav.salesapp.setupclosinghour",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-salevel",
        path: `${APP_PREFIX_PATH}/sales-app/sa-level`,
        title: "sidenav.salesapp.upgradesalevel",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-historyachievement",
        path: `${APP_PREFIX_PATH}/sales-app/history-achievement`,
        title: "sidenav.salesapp.historyachievement",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-historyachievementsku",
        path: `${APP_PREFIX_PATH}/sales-app/history-achievement-sku`,
        title: "sidenav.salesapp.historyachievementbysku",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-visitoutlet",
        path: `${APP_PREFIX_PATH}/sales-app/visit-outlet`,
        title: "sidenav.salesapp.visitoutlet",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-scvisitoutlet",
        path: `${APP_PREFIX_PATH}/sales-app/sc-visit-outlet`,
        title: "sidenav.salesapp.scvisitmerchant",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-competitorprice",
        path: `${APP_PREFIX_PATH}/sales-app/competitor-price`,
        title: "sidenav.salesapp.competitorprice",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-blitzdailyproducttarget",
        path: `${APP_PREFIX_PATH}/sales-app/blitz-target`,
        title: "sidenav.salesapp.blitztargetproduct",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-lastlocation",
        path: `${APP_PREFIX_PATH}/sales-app/sa-last-location`,
        title: "sidenav.salesapp.lastareasa",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-picmerchant",
        path: `${APP_PREFIX_PATH}/sales-app/pic-merchant`,
        title: "sidenav.salesapp.picmerchant",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-achievementsales",
        path: `${APP_PREFIX_PATH}/sales-app/achievement-sales`,
        title: "sidenav.salesapp.achievementsales",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-samission",
        path: `${APP_PREFIX_PATH}/sales-app/sa-mission`,
        title: "sidenav.salesapp.missions",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-app-turnovermissionssetting",
        path: `${APP_PREFIX_PATH}/sales-app/turnover-missions-setting`,
        title: "sidenav.salesapp.turnovermissionssetting",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const approval = [
  {
    key: "approval",
    path: `${APP_PREFIX_PATH}/approval`,
    title: "sidenav.approval",
    icon: FileProtectOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const partnersNavTree = [
  {
    key: "partners-nav",
    path: `${APP_PREFIX_PATH}/partners`,
    title: "sidenav.partners",
    icon: SolutionOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "catalog-community",
        path: `${APP_PREFIX_PATH}/partners/catalog-community`,
        title: "sidenav.partners.catalogcommunity",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "partners-mapping",
        path: `${APP_PREFIX_PATH}/partners/partners-mapping`,
        title: "sidenav.partners.partnerbranchgroupmapping",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "orders-by-community",
        path: `${APP_PREFIX_PATH}/partners/orders-by-community`,
        title: "sidenav.partners.ordersbycommunity",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "orders-by-motorist",
        path: `${APP_PREFIX_PATH}/partners/orders-by-motorist`,
        title: "sidenav.partners.ordersbymotorist",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "orders-by-pos",
        path: `${APP_PREFIX_PATH}/partners/orders-by-pos`,
        title: "sidenav.partners.ordersbypos",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "activation-pos-for-stockist",
        path: `${APP_PREFIX_PATH}/partners/activation-pos-for-stockist`,
        title: "sidenav.partners.activationposforstockist",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...systemNavTree,
  ...masterNavTree,
  ...stockNavTree,
  ...purchaseNavTree,
  ...salesNavTree,
  ...rewardNavTree,
  ...campaignNavTree,
  // ...bomNavTree,
  ...bankcashNavTree,
  ...reportNavTree,
  ...salesAppNavTree,
  ...penetrationMapNavTree,
  ...catalogMerchant,
  ...approval,
  ...partnersNavTree,
];

export default navigationConfig;
