import FetchInterceptor from "configs/FetchInterceptor";

class DashboardServices {
  static async getDataTransaction(requestBody) {
    let apiUrl = `/v1/dashboard/sales-order/gtv`;
    let response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getCountStockist(requestBody) {
    let apiUrl = `/v1/dashboard/branches/count`;
    let response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getGoodRank(props) {
    let {limit, offset} = props;
    let apiUrl = `/v1/dashboard/goods/ranking?limit=${limit}&offset=${offset}`;
    let response = await FetchInterceptor.post(apiUrl, props.reqBody);
    return response;
  }

  static async getSalesOrderTotal(requestBody) {
    let apiUrl = `/v1/dashboard/sales-orders/total/${requestBody?.periode}`;
    let response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getSalesOrderMonthly(requestBody) {
    let apiUrl = `/v1/dashboard/monthly-sales-order`;
    let response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }

  static async getCustomerTotal(requestBody) {
    let apiUrl = `/v1/dashboard/customer/count`;
    let response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getCustomerVerified(requestBody) {
    let apiUrl = `/v1/dashboard/customer/verified`;
    let response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getActiveOutlet(requestBody) {
    let apiUrl = `/v1/dashboard/active-outlet`;
    let response = await FetchInterceptor.get(apiUrl, {
      params: {
        limit: 10,
        offset: 0,
        ...requestBody,
      },
    });
    return response;
  }

  static async getCustomerTransaction(requestBody) {
    let apiUrl = `/v1/dashboard/customer/active`;
    let response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async targetDaily(requestBody) {
    let apiUrl = `/v1/dashboard/daily-target`;
    let response = await FetchInterceptor.get(apiUrl, {params: requestBody});
    return response;
  }

  static async totalSaleAllStockist(requestBody) {
    let apiUrl = `/v1/dashboard/stockist-performance`;
    let response = await FetchInterceptor.get(apiUrl, {
      params: {
        limit: 50,
        offset: 0,
        ...requestBody,
      },
    });
    return response;
  }

  static async totalSaleAllSC(requestBody) {
    let apiUrl = "/v1/dashboard/sc-performance";
    let response = await FetchInterceptor.get(apiUrl, {
      params: {
        limit: 10,
        offset: 0,
        ...requestBody,
      },
    });
    return response;
  }

  static async getVisitOutlet(props) {
    let {date, branchIds, scIds} = props;
    let apiUrl = `/v1/dashboard/customer-visit`;
    let response = await FetchInterceptor.post(apiUrl, {branchIds, scIds, date});
    return response;
  }

  static async getActiveMotoristCount(reqBody) {
    let apiUrl = "/v1/dashboard/active-motorist/count";
    let response = await FetchInterceptor.get(apiUrl, {params: reqBody});
    return response;
  }

  static async getMerchantPOSCount(reqBody) {
    let apiUrl = "/v1/dashboard/transaction-count";
    let response = await FetchInterceptor.get(apiUrl, {params: reqBody});
    return response;
  }
}

export default DashboardServices;
