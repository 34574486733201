import { useEffect, useState, useRef } from "react";
import { isEqual } from "lodash";

import FetchInterceptor from "configs/FetchInterceptor";

function useDeepEffect(fn, deps) {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isFirstEffect = isFirst.current;
    const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

    isFirst.current = false;
    prevDeps.current = deps;

    if (isFirstEffect || !isSame) {
      return fn();
    }
  }, deps);
}

export function useFetchAPI(props) {
  let { path, method, queryParams, body, options } = props;
  let fetchTrigger = options?.fetchTrigger;

  let [data, setData] = useState(null);
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(null);

  let controllerRef = useRef(new AbortController());
  let cancel = () => {
    controllerRef.current.abort();
  };

  let [triggerRefetch, setTriggerRefetch] = useState(false);

  useDeepEffect(() => {
    if (!fetchTrigger) {
      setLoading(true);
      setData(null);
      setError(null);
      FetchInterceptor.request({
        url: path,
        method,
        signal: controllerRef.current.signal,
        ...(queryParams ? { params: queryParams } : {}),
        ...(body ? { data: body } : {}),
      })
        .then((response) => {
          setData(response);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [triggerRefetch, queryParams, body, fetchTrigger]);

  return {
    data,
    loading,
    error,
    cancel,
    refetch: () => setTriggerRefetch(!triggerRefetch),
  };
}
