import FetchInterceptor from "configs/FetchInterceptor";

class GeneralJournalServices {
  static async getAll(params = {}) {
    const apiUrl = "/v1/manual-journal/general/history";
    const response = await FetchInterceptor.get(apiUrl, { params });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/v1/manual-journal/history/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/v1/manual-journal/general";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default GeneralJournalServices;
