import FetchInterceptor from "configs/FetchInterceptor";

class WarehouseServices {
  static async getAll(requestBody) {
    let apiUrl = "/v1/warehouses";
    let response = await FetchInterceptor.get(apiUrl, { params: requestBody });
    return response;
  }

  static async getById(id) {
    let apiUrl = `/v1/warehouses/${id}`;
    let response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getByIds(requestBody) {
    let apiUrl = "/core/v1/warehouses/get/data";
    let response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    let apiUrl = "/v1/warehouses";
    let response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    let apiUrl = "/v1/warehouses";
    let response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async checkPrimary(props) {
    let { branchId } = props;
    let apiUrl = `/v1/warehouses-primary`;
    let response = await FetchInterceptor.get(apiUrl, { params: { branchId } });
    return response;
  }
}

export default WarehouseServices;
