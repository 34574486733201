import {GREY, WHITE_SMOKE} from "constants/colors";
import {IS_DEVELOPMENT} from "constants/GlobalConstant";

const DevelopmentBox = () => {
  if (IS_DEVELOPMENT) {
    return <div style={styles.container}>Development Mode</div>;
  }

  return null;
};

const styles = {
  container: {
    background: GREY,
    opacity: 0.7,
    color: WHITE_SMOKE,
    textAlign: "center",
    padding: "5px 15px ",
    bottom: 0,
    left: 0,
    position: "fixed",
    zIndex: 99,
  },
};

export default DevelopmentBox;
