export const WHITE_SMOKE = "#f4f4f4";
export const SOFT_NAVY = "#455560";
export const GREY = "#707070";
export const GREEN_PRIMARY = "#3CB144";
export const RED_PRIMARY = "#D42E11";
export const YELLOW_PRIMARY = "#FCC917";

export const TABLE = {
  header: WHITE_SMOKE,
};
