import React, {useState, useEffect, useCallback} from "react";
import {Menu, Dropdown, List, message, Input} from "antd";
import {CheckOutlined, ClusterOutlined} from "@ant-design/icons";

import Flex from "components/shared-components/Flex";
import FetchInterceptor from "configs/FetchInterceptor";

// type Props = {
//   selectedBranchGroup: {
//     id: String,
//     name: String,
//   } | null,
//  onChange: (item) => void,
// };

export function NavBranchGroup({selectedBranch, onChange}) {
  let [visible, setVisible] = useState(false);
  let [rows, setRows] = useState([]);
  let [searchValue, setSearchValue] = useState("");

  let filterRows = rows.filter((item) => {
    return String(item.name).toLowerCase().includes(searchValue.toLowerCase());
  });

  useEffect(() => {
    if (visible) {
      getBranchGroup();
    }
  }, [visible]);

  let getBranchGroup = useCallback(async () => {
    try {
      let {data} = await FetchInterceptor.get("/v2/branch-group", {
        params: {
          limit: 10,
          offset: 0,
          roleFilter: true,
          ...(searchValue ? {search: searchValue} : {}),
        },
      });
      let listItem = [{id: "all", name: "All"}, ...(data?.rows ?? [])].map((item) => ({
        id: item.id,
        name: item.name,
      }));
      // TODO : change with better approach
      let selectedItem = listItem.find((item) => item.id === selectedBranch.id);
      let restItem = listItem.filter((item) => item.id !== selectedBranch.id);
      setRows([selectedItem, ...restItem]);
    } catch (error) {
      message.error(`Terjadi kesalahan : ${error.message}`);
    }
  });

  let onSelected = useCallback((item) => {
    setVisible(false);
    onChange(item);
  });

  let notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="m-0">
        <Input
          placeholder="Search"
          bordered={false}
          onChange={(event) => setSearchValue(event.target.value)}
        />
      </div>
      <div className="nav-notification-body">
        {getBranchGroupBody(filterRows, selectedBranch, onSelected)}
      </div>
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={setVisible}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item key="branch-group">
          <div>
            <ClusterOutlined className="nav-icon mx-auto" type="bell" />
          </div>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

function getBranchGroupBody(list, selectedBranch, onSelected) {
  return list.length > 0 ? (
    <List
      size="large"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable" onClick={() => onSelected(item)}>
          <Flex alignItems="center">
            <div className="mr-3">
              <span className="font-weight-bold text-dark">{item.name} </span>
              {selectedBranch && selectedBranch.id === item.id && <CheckOutlined />}
            </div>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">Data tidak ditemukan</div>
  );
}

export default NavBranchGroup;
